import ms from 'ms'
import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import styled, { css } from 'styled-components/macro'
import colors from '../colors.js'
import { Anchor, Code, Heading2, Heading3, Paragraph, Section } from '../Html.js'
import QuestionSVG from './question.svg'

const QueueTimeList = styled.ul`
  list-style-type: none;
  margin: 40px 0 0;
  padding: 0;
  max-width: 700px;

  @media screen and (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px 40px;
  }
`

const QueueTime = styled.li`
  margin: 0 0 30px;
  padding: 0;

  @media screen and (min-width: 640px) {
    margin: 0;
  }
`

const Question = styled.span`
  display: inline-block;
  opacity: 0.4;
  padding: 0;
  transition: opacity 0.2s ease-in-out;
  width: 1em;
  margin-left: 0.2em;

  img {
    display: inline-block;
    width: 100%;
    height: auto;
    vertical-align: middle;
  }

  &:hover {
    opacity: 0.7;
  }
`

const QueueTimeStatus = styled.div`
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0.33em;
  ${(props) =>
    !props.ok &&
    css`
      color: ${colors.orangeAlt};
    `}
`

const Error = styled.div`
  padding: 80px 0;
  border: 1px solid ${colors.blue700};
  border-radius: 8px;
  margin-top: 60px;
  text-align: center;
`

function QueueTimes({ queueTimes, error }) {
  useEffect(() => {
    if (queueTimes) {
      ReactTooltip.rebuild()
    }
  }, [queueTimes])

  return (
    <Section>
      <Heading2>Current queue times</Heading2>
      <Paragraph>
        You can also access queue times via{' '}
        <Code>
          GET{' '}
          <Anchor href="https://transloaditstatus.com/queue.json">
            https://transloaditstatus.com/queue.json
          </Anchor>
        </Code>
      </Paragraph>
      {error && (
        <Error>
          <p>Failed to fetch queues 😕</p>
        </Error>
      )}
      <QueueTimeList>
        {queueTimes &&
          queueTimes.map(({ regionLabel, byType, queueError }) =>
            Object.entries(byType).map(([type, robots]) => {
              const robotsWithDelay = Object.entries(robots).filter(([_, { ok }]) => !ok)
              return (
                <QueueTime key={`${regionLabel}${type}`}>
                  <Heading3>
                    {regionLabel} {type} queues{' '}
                    <Question
                      data-tip={
                        type === 'live'
                          ? `Real time processing with limited capacity. See <a href="https://transloadit.com/docs/api/#queues">API docs</a>.`
                          : `Regular speed queues with unlimited capacity. See <a href="https://transloadit.com/docs/api/#queues">API docs</a>.`
                      }
                    >
                      <img src={QuestionSVG} alt="(?)" />
                    </Question>
                  </Heading3>
                  {queueError ? (
                    <QueueTimeStatus>Failed to fetch queues</QueueTimeStatus>
                  ) : robotsWithDelay.length ? (
                    <>
                      {robotsWithDelay.map(([robot, { wait }]) => (
                        <QueueTimeStatus key={robot}>
                          {robot}: {ms(wait)}
                        </QueueTimeStatus>
                      ))}
                      <QueueTimeStatus ok>✓ All other Robots 0s</QueueTimeStatus>
                    </>
                  ) : (
                    <QueueTimeStatus ok>✓ All Robots 0s</QueueTimeStatus>
                  )}
                </QueueTime>
              )
            }),
          )}
      </QueueTimeList>
    </Section>
  )
}

export default QueueTimes
