const colors = {
  blue100: '#dde7f8',
  blue200: '#a2b1cc',
  blue300: '#8495b2',
  blue400: '#71809b',
  blue500: '#5a6a89',
  blue600: '#173977',
  blue700: '#1e2b45',
  blue800: '#0d1d3c',
  blue900: '#031029',
  blue950: '#040d1f',
  orange: '#f4802a',
  orangeAlt: '#e6921c',
  orangeLight: '#f4c11e',
  green: '#0e9b5f',
  greenLight: '#17e07f',
  turquoise: '#2adbae',
}

export default colors
