import React, { useMemo } from 'react'
import useSWR from 'swr'
import About from './About.js'
import { baseUrl, regions } from './config.js'
import Container from './Container.js'
import Footer from './Footer.js'
import Graphs from './Graphs.js'
import Header from './Header/Header.js'
import IncidentHistory from './IncidentHistory/IncidentHistory.js'
import Layout from './Layout.js'
import MainStatus from './MainStatus/MainStatus.js'
import QueueTimes from './QueueTimes/QueueTimes.js'

const refreshInterval = 60_000

function Statuspage() {
  const fetcher = (resource, init) =>
    fetch(resource, init).then((res) =>
      res.ok
        ? res.json()
        : Promise.reject(new Error(`Failed to load with status ${res.status} ${res.statusText}`)),
    )

  const incidents = useSWR(`${baseUrl}/incidents.json`, fetcher, { refreshInterval })
  const web = useSWR(`${baseUrl}/web.json`, fetcher, { refreshInterval })
  const queue = useSWR(`${baseUrl}/queue.json`, fetcher, { refreshInterval })
  const slots = useSWR('https://api2.transloadit.com/robot_slots', fetcher, { refreshInterval })
  const apiRegions = useSWR(`${baseUrl}/api.json`, () =>
    Promise.all(
      Object.entries(regions).map(async ([region, info]) => {
        const res = await fetch(`${baseUrl}/api.json?region=${region}`)
        const body = await res.json()
        return { ...info, online: body.status === 'Ok' }
      }),
    ),
  )

  const services = useMemo(() => {
    if (!web.data || !apiRegions.data) return
    const services = [
      {
        label: 'Website',
        keywords: ['website', 'console'],
        online: web.data.status === 'Ok',
        downtimeIfNoServiceMentionedInTweet: false,
      },
    ]
    for (const { label, keywords, online } of apiRegions.data) {
      services.push({
        label: `${label} API`,
        keywords,
        online,
        downtimeIfNoServiceMentionedInTweet: true,
      })
    }
    return services
  }, [web.data, apiRegions.data])

  const incidentsByDay = useMemo(() => {
    if (!incidents.data) return
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const incidentsByDay = Object.create(null)
    for (const incident of incidents.data) {
      const day = incident.datetime
      ;(incidentsByDay[day] ??= []).push(incident)
    }
    return Object.fromEntries(
      Object.entries(incidentsByDay).map(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        ([day, incidents]) => [new Date(day).toDateString(), incidents],
      ),
    )
  }, [incidents.data])

  const queueTimes = useMemo(() => {
    if (!slots.data || !queue.data) return

    const values = Object.values(queue.data)
    if (values.length === 1) return

    const queueTimes = []
    const types = Object.keys(values[0])
    for (const [region, { label: regionLabel }] of Object.entries(regions)) {
      const entry = { regionLabel, byType: [], queueError: null }
      for (const type of types) {
        entry.byType[type] = {}
        try {
          for (const [slot, { wait }] of Object.entries(queue.data[`apiQueue/${region}`][type])) {
            for (const robot of slots.data[slot]) {
              if (robot.includes('/assembly')) continue
              entry.byType[type][robot] = {
                wait,
                ok:
                  (type === 'urltransform' && wait < 500) ||
                  (type === 'live' && wait < 60_000) ||
                  (type === 'batch' && wait < 20 * 60_000) ||
                  (type === 'backup' && wait < 2 * 60 * 60_000) ||
                  wait === 0,
              }
            }
          }
        } catch (err) {
          console.error('Invalid /queue.json response', err)
          entry.queueError = err
        }
      }
      queueTimes.push(entry)
    }
    return queueTimes
  }, [slots.data, queue.data])

  return (
    <Layout>
      <Header>Platform Status</Header>
      <Container>
        <MainStatus services={services} error={web.error || apiRegions.error} />
        <Graphs
          services={services}
          incidentsByDay={incidentsByDay}
          error={web.error || apiRegions.error || incidents.error}
        />
        <About />
        <IncidentHistory incidentsByDay={incidentsByDay} error={incidents.error} />
        <QueueTimes queueTimes={queueTimes} error={queue.error || slots.error} />
      </Container>
      <Footer />
    </Layout>
  )
}

export default Statuspage
