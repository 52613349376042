import styled from 'styled-components/macro'
import { Section, Paragraph, Anchor, Code } from './Html.js'

const StyledSection = styled(Section)`
  font-weight: 500;
`

function About() {
  return (
    <StyledSection>
      <Paragraph>
        Our global API endpoint (
        <Anchor href="https://api2.transloadit.com">api2.transloadit.com</Anchor>) automatically
        routes requests to the closest region.
      </Paragraph>
      <Paragraph>
        If one of our API endpoints is down, your app can switch to another region. Transloadit does
        not automate this as it could drive up cross-regional bills or even violate laws (where data
        must not leave a certain area).{' '}
        <Anchor href="https://transloadit.com/support">Reach out to support</Anchor> if you would
        like assistance with automating regional failover in your app.
      </Paragraph>
      <Paragraph>
        You can also access our API’s status via{' '}
        <Code>
          GET{' '}
          <Anchor href="https://transloaditstatus.com/api.json">
            https://transloaditstatus.com/api.json
          </Anchor>
        </Code>
        , or by following <Anchor href="https://twitter.com/TLStatus">@TLStatus</Anchor> on Twitter.
      </Paragraph>
    </StyledSection>
  )
}

export default About
