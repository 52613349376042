import React, { useState, useMemo } from 'react'
import useSWR from 'swr'
import styled, { css } from 'styled-components/macro'
import { formatDistanceToNowStrict, subMinutes } from 'date-fns'
import { transparentize } from 'polished'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import Layout from './Layout.js'
import Header from './Header/Header.js'
import { Anchor, Section } from './Html.js'
import Container from './Container.js'
import colors from './colors.js'
import { regions } from './config.js'

const Table = styled.table`
  padding: 1em;
  padding-top: 0;
  text-align: left;
  white-space: nowrap;
`
const StickyTh = styled.th`
  position: sticky;
  top: 61px;
  ${(props) =>
    props.onClick &&
    css`
      :hover {
        cursor: pointer;
      }
    `}
`
const Input = styled.input`
  color: white;
  margin-bottom: 1ex;
  background-color: transparent;
  border: 1px solid ${transparentize(0.7, 'white')};
  transition: border-color 0.2s ease-in-out;
  border-radius: 4px;
  padding: 6px 8px;
  width: 100%;
  outline: none;
  :hover,
  :focus {
    border-color: ${transparentize(0.5, 'white')};
  }
`
const StyledSection = styled(Section)`
  margin-bottom: 20px;
  text-align: right;
`
const Errors = styled.span`
  color: ${colors.orange};
  ${Anchor} {
    color: inherit;
  }
`
const StickyContainer = styled(Container)`
  position: sticky;
  top: 0;
  padding-top: 1em;
  padding-bottom: 8px;
  background-color: ${colors.blue900};
  max-width: none;
  ${Section} {
    margin-bottom: 0;
  }
`
const StyledHeader = styled(Header)`
  margin-bottom: 0 !important;
`
const Load = styled.span`
  font-variant-numeric: tabular-nums;
`

function MachIndex() {
  const location = useLocation()
  const history = useHistory()
  const params = useParams()
  const [servingFromLocalStorage, setServingFromLocalStorage] = useState()

  const query = params.query || ''
  const queryString = new URLSearchParams(location.search)
  const sort = {
    column: queryString.get('sort'),
    asc: queryString.get('asc') === 'true',
  }

  const instances = useSWR(
    'instances',
    async () => {
      const ac = new AbortController()
      // Get instances from the first available region and ignore failures from
      // others
      let body = await Promise.any(
        [...Object.keys(regions), ''].map(async (region) => {
          const res = await fetch(`/machindex/request/instances/${region}`, { signal: ac.signal })
          // eslint-disable-next-line no-throw-literal
          if (!res.ok) throw null // Let's not bother creating an Error instance as Promise.any swallows it anyway.
          return res.json()
        }),
      )
      ac.abort() // Abort still on-going requests.

      if (body) {
        localStorage.setItem('machindex-instances', JSON.stringify({ instances: body.instances }))
        setServingFromLocalStorage(false)
      } else {
        try {
          body = JSON.parse(localStorage.getItem('machindex-instances'))
          setServingFromLocalStorage(true)
        } catch (err) {
          console.error(err)
        }
      }

      if (!body) return
      const { instances: newInstances } = body
      await Promise.all(
        newInstances.map(async (instance) => {
          const instanceName = instance.hostname.replace(/\.transloadit\.com$/, '')
          if (instance.cluster === 'staging') {
            try {
              const res = await fetch(
                `/machindex/request/code_version/${instanceName}?role=${instance.role}`,
              )
              instance.build_id = await res.json()
            } catch (err) {
              console.error(err)
            }
          }

          const repoName = instance.role === 'webserver' ? 'crm' : 'api2'

          instance.build_link = `https://github.com/transloadit/${repoName}/actions/runs/${instance.build_id}/`
          instance.papertrail_link = `https://papertrailapp.com/systems/${instanceName}/events`
          instance.ec2_link = `https://console.aws.amazon.com/ec2/v2/home?region=${instance.region}#Instances:search=${instanceName};sort=desc:launchTime`
        }),
      )
      return newInstances
    },
    {
      refreshInterval: 5000,
    },
  )

  const instancesFiltered = useMemo(() => {
    if (!instances.data) return
    if (query === '') return instances.data
    return instances.data
      .map((instance) => [
        instance,
        Object.entries(instance)
          .filter(([key]) => !key.includes('_link'))
          .map(([_, value]) => value)
          .join('')
          .toLowerCase(),
      ])
      .filter(([_, valuesString]) =>
        query
          .trim()
          .toLowerCase()
          .split(/,| /)
          .every((word) => valuesString.includes(word)),
      )
      .map(([instance]) => instance)
  }, [instances, query])

  const instancesSortedAndFiltered = useMemo(() => {
    if (!instancesFiltered) return
    if (!sort.column) return instancesFiltered
    return instancesFiltered.sort((a, b) => {
      const aValue = a[sort.column]
      const bValue = b[sort.column]
      let res
      if (typeof aValue === 'number') {
        res = sort.asc ? aValue - bValue : bValue - aValue
      } else {
        res = aValue.localeCompare(bValue)
        if (!sort.asc) res *= -1
      }
      return res
    })
  }, [instancesFiltered, sort.column, sort.asc])

  const updateUrl = (state) => {
    const newQuery = Object.keys(state).includes('query') ? state.query : query
    const newColumn = state.column || sort.column
    const newAsc = Object.keys(state).includes('asc') ? state.asc : sort.asc
    let url = `/machindex/${newQuery}`
    if (newColumn) {
      url += `?sort=${newColumn}&asc=${newAsc}`
    }
    history.replace(url)
  }

  const onSortByColumn = (column) => {
    const asc = column === sort.column ? !sort.asc : sort.asc
    updateUrl({ column, asc })
  }

  return (
    <Layout>
      <StyledHeader>MachIndex</StyledHeader>
      <StickyContainer>
        <StyledSection>
          <Input
            type="text"
            placeholder="Search"
            defaultValue={query}
            onChange={(ev) => {
              updateUrl({ query: ev.target.value })
            }}
          />
          {servingFromLocalStorage && (
            <>
              <Errors>{servingFromLocalStorage && 'Serving from localStorage! '}</Errors>{' '}
            </>
          )}
          {(instancesSortedAndFiltered || []).length} results
        </StyledSection>
      </StickyContainer>
      <Table width="100%">
        <thead>
          <tr>
            <StickyTh />
            <StickyTh onClick={() => onSortByColumn('hostname')}>Hostname</StickyTh>
            <StickyTh onClick={() => onSortByColumn('region')}>Region</StickyTh>
            <StickyTh onClick={() => onSortByColumn('zone')}>Zone</StickyTh>
            <StickyTh onClick={() => onSortByColumn('cluster')}>Cluster</StickyTh>
            <StickyTh onClick={() => onSortByColumn('role')}>Role</StickyTh>
            <StickyTh onClick={() => onSortByColumn('fleet')}>Fleet</StickyTh>
            <StickyTh onClick={() => onSortByColumn('state')}>State</StickyTh>
            <StickyTh onClick={() => onSortByColumn('build_id')}>Build</StickyTh>
            <StickyTh onClick={() => onSortByColumn('machine_type')}>Machine</StickyTh>
            <StickyTh onClick={() => onSortByColumn('created_m_ago')}>Age</StickyTh>
            <StickyTh onClick={() => onSortByColumn('load_per_core')}>Load/core</StickyTh>
            <StickyTh colSpan={3}>Links</StickyTh>
          </tr>
        </thead>
        <tbody>
          {(instancesSortedAndFiltered || []).map((instance) => (
            <tr key={instance.id}>
              <td>
                {instance.is_being_replaced === 1 && (
                  <>
                    {' '}
                    <span title="machine is being replaced">⏳</span>
                  </>
                )}
              </td>
              <td>{instance.hostname}</td>
              <td>{instance.region}</td>
              <td>{instance.zone}</td>
              <td>{instance.cluster}</td>
              <td>{instance.role}</td>
              <td>{instance.fleet}</td>
              <td>{instance.state}</td>
              <td>
                <Anchor target="_blank" rel="noreferrer" href={instance.build_link}>
                  {instance.build_id}
                </Anchor>
              </td>
              <td>
                <Anchor
                  target="_blank"
                  rel="noreferrer"
                  href={`http://www.ec2instances.info/?cost_duration=monthly&selected=${instance.machine_type}`}
                >
                  {instance.machine_type}
                </Anchor>
              </td>
              <td>{formatDistanceToNowStrict(subMinutes(new Date(), instance.created_m_ago))}</td>
              <td>
                <Load>{instance.load_per_core.toFixed(2)}</Load>
              </td>
              <td>
                <Anchor target="_blank" rel="noreferrer" href={instance.instance_status}>
                  status
                </Anchor>
              </td>
              <td>
                <Anchor target="_blank" rel="noreferrer" href={instance.papertrail_link}>
                  papertrail
                </Anchor>
              </td>
              <td>
                <Anchor target="_blank" rel="noreferrer" href={instance.ec2_link}>
                  ec2
                </Anchor>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Layout>
  )
}

export default MachIndex
