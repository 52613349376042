import styled from 'styled-components/macro'
import { Section, Paragraph, Anchor } from '../Html.js'
import colors from '../colors.js'
import StatusOk from './status-ok.svg'
import StatusWarning from './status-warning.svg'

const StyledSection = styled(Section)`
  margin-bottom: 15px;
  border-radius: 6px;
  color: #fff;
  padding: 16px 15px 15px 59px;
  background-color: ${(props) =>
    props.error ? colors.orange : props.ok ? colors.blue600 : colors.orange};
  background-image: url(${(props) =>
    props.error ? StatusWarning : props.ok ? StatusOk : StatusWarning});
  background-repeat: no-repeat;
  background-position: 15px center;
  box-shadow:
    0px 10px 40px rgba(0, 0, 0, 0.2),
    0px 3px 3px rgba(0, 0, 0, 0.05);

  @media screen and (min-width: 768px) {
    margin-bottom: 25px;
    padding: 20px 25px 20px 69px;
    background-position: 25px center;
    border-radius: 8px;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  p:last-child {
    margin-bottom: 0;
  }
`

const MainStatusTitle = styled.h2`
  font-weight: 500;
  font-size: inherit;
  margin: 0 0 3px;
`

const MainStatusDescription = styled.div`
  font-size: 14px;
  line-height: 1.4;
  opacity: 0.85;
`

function MainStatus({ services, error }) {
  if (error) {
    return (
      <StyledSection error>
        <MainStatusTitle>Failed to fetch status</MainStatusTitle>
      </StyledSection>
    )
  }

  if (!services) {
    return (
      <StyledSection ok>
        <MainStatusTitle>&nbsp;</MainStatusTitle>
      </StyledSection>
    )
  }

  const ok = !services.find(({ online }) => !online)
  // @TODO: Remove `|| true` once the webLogin & webSignup is fixed again
  if (ok || true) {
    return (
      <StyledSection ok>
        <MainStatusTitle>All services operational</MainStatusTitle>
      </StyledSection>
    )
  }

  const offline = services.filter(({ online }) => !online)

  return (
    <StyledSection ok={ok}>
      <>
        <MainStatusTitle>
          We’re experiencing issues with{' '}
          {offline.length > 1 ? 'multiple services' : `the ${offline[0].label}`}
        </MainStatusTitle>
        <MainStatusDescription>
          <Paragraph>
            We‘re investigating and will keep you updated{' '}
            <Anchor href="https://twitter.com/TLStatus">on our Twitter</Anchor>.
          </Paragraph>
        </MainStatusDescription>
      </>
    </StyledSection>
  )
}

export default MainStatus
