import styled from 'styled-components/macro'

const Container = styled.div`
  max-width: 880px;
  margin: 0 auto;
  padding: 0 15px;

  @media screen and (min-width: 768px) {
    padding: 0 20px;
  }
`

export default Container
