import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.js'
import { history } from './history.js'
import reportWebVitals from './reportWebVitals.js'

Sentry.init({
  dsn: 'https://33cae45093ea45bdbf98f362e1def4c7@o510507.ingest.sentry.io/5848932',
  environment: process.env.NODE_ENV,
  // This should be identical to the release name created by the Sentry CLI in the GitHub Actions workflow
  release: `${process.env.REACT_APP_VERSION}`,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
