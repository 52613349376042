import React, { Component } from 'react'

class CurrentTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTime: this.getTime(),
    }
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.updateTime(), 10_000)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  getTime() {
    const now = new Date()
    const hours = this.formatTime(now.getUTCHours())
    const minutes = this.formatTime(now.getUTCMinutes())
    const am = hours < 12 ? 'am' : 'pm'
    return `"${hours}:${minutes}${am} UTC - "`
  }

  formatTime(time) {
    return time.toString().padStart(2, '0')
  }

  updateTime() {
    this.setState({
      currentTime: this.getTime(),
    })
  }

  render() {
    return <span>{this.state.currentTime}</span>
  }
}

export default CurrentTime
