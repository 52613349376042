import { useState } from 'react'
import styled from 'styled-components/macro'
import useSWR from 'swr'
import Container from './Container.js'
import CurrentTime from './CurrentTime.js'
import Header from './Header/Header.js'
import { Heading3, Section } from './Html.js'
import Layout from './Layout.js'
import colors from './colors.js'
import { baseUrl } from './config.js'

// Unfortunately this is not allowed by webpack, so we need to hard-code
// our regions here for now.
// import config from '../../server/lib/config'
const config = {
  regions: ['us-east-1', 'eu-west-1', 'ap-southeast-1', 'website'],
}

const StyledSection = styled(Section)`
  margin-bottom: 20px;
  padding: 20px 30px 25px;
  background-color: ${colors.blue800};
  box-shadow:
    0px 10px 40px rgba(0, 0, 0, 0.25),
    0px 3px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

const StyledHeader = styled(Header)`
  margin-bottom: 0 !important;
`

const Form = styled.form`
  display: flex;
  margin: 1.5rem 0;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  justify-content: center;
  gap: 0.5rem;
`

const Input = styled.input`
  margin-left: 0.5rem;
`

const Label = styled.label`
  display: block;
`

const Fieldset = styled.fieldset`
  border: 0;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
`

function AdminPage() {
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')

  const isAuthenticated = user !== '' && password !== ''

  return (
    <Layout>
      <StyledHeader>Incidents</StyledHeader>
      <Container>
        {!isAuthenticated && (
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              const data = new FormData(event.target)
              setUser(data.get('username'))
              setPassword(data.get('password'))
            }}
          >
            <label>
              Username
              <Input type="text" name="username" placeholder="Username" required />
            </label>

            <label>
              Password
              <Input type="password" name="password" placeholder="Password" required />
            </label>
            <button type="submit">log in</button>
          </Form>
        )}

        {isAuthenticated && <Incidents user={user} password={password} />}
      </Container>
    </Layout>
  )
}

function Incidents(props) {
  const { user, password } = props

  const { data: incidents, error } = useSWR(
    !user || !password ? null : [user, password, `${baseUrl}/incidents/request/incidents`],
    async ([user, password, url]) => {
      const response = await fetch(url, {
        headers: {
          Authorization: `Basic ${btoa(`${user}:${password}`)}`,
        },
      })

      const data = await response.json()
      return data
    },
  )

  if (error) {
    return <div>Error loading incidents. {error.message}</div>
  }

  return (
    <>
      <h3>Incidents List</h3>
      {incidents?.length > 0 ? (
        incidents.map((incident) => (
          <IncidentForm key={incident.id} incident={incident} user={user} password={password} />
        ))
      ) : (
        <div>Loading incidents...</div>
      )}
    </>
  )
}

function IncidentForm({ incident, user, password }) {
  const { id, datetime } = incident

  const [text, setText] = useState(incident.text)
  const [regions, setRegions] = useState(incident.regions)
  const [updateError, setUpdateError] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)

  const handleFormSubmit = async (event) => {
    event.preventDefault()

    const payload = {
      id,
      datetime,
      text,
      regions,
    }

    setIsUpdating(true)
    setUpdateError(null)

    try {
      const response = await fetch(`${baseUrl}/incidents/request/incidents/${id}`, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${user}:${password}`)}`,
        },
      })

      if (!response.ok) {
        throw new Error(`Request failed: ${await response.text()}`)
      }
    } catch (err) {
      console.error('Failed to update incident')
      setUpdateError(err.message)
    } finally {
      setIsUpdating(false)
    }
  }

  // Format is: Thursday, 20 December 2012
  const formattedDatetime = new Date(datetime).toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <StyledSection>
      <p>
        Please keep incidents short and to the point. Prefix them with the current UTC date in
        descending order. Example:
      </p>
      <p>
        02:20 UTC - We have figured out the issue and deployed a fix. We can see in our logs that
        things are improving.
        <br />
        <br />
        02:12 UTC - We have identified the issue and are working on a patch.
      </p>
      <hr />
      <p>
        Here is the current time to copy/paste into your incident update:{' '}
        <strong>
          <CurrentTime />
        </strong>
      </p>

      <form onSubmit={handleFormSubmit}>
        {updateError && <p>Failed to update incident: {updateError}</p>}
        <Fieldset disabled={isUpdating}>
          {/* Incident time */}
          <Heading3>{formattedDatetime}</Heading3>
          {/* Incident description */}
          <Label>
            Description: <br />
            <Textarea value={text} onChange={(e) => setText(e.target.value)} />
          </Label>
          {/* Region selection */}
          <Label>
            Regions: <br />
            <select
              multiple
              value={regions}
              onChange={(e) =>
                setRegions(Array.from(e.target.selectedOptions, (option) => option.value))
              }
            >
              {config.regions.map((region) => (
                <option key={region} value={region}>
                  {region}
                </option>
              ))}
            </select>
          </Label>

          {/* Submit button */}
          <button type="submit">{isUpdating ? 'Updating...' : `Update Incident`}</button>
        </Fieldset>
      </form>
    </StyledSection>
  )
}

export default AdminPage
