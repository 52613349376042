import React, { Fragment, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { format, subDays, startOfDay } from 'date-fns'
import colors from '../colors.js'
import { anchorStyle, Heading2, Section } from '../Html.js'

const StyledSection = styled(Section)`
  font-size: 16px;
`

const IncidentList = styled.dl`
  @media screen and (min-width: 640px) {
    display: grid;
    grid-template: auto/150px 1fr;
  }
`

const incidentItem = css`
  @media screen and (min-width: 768px) {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid ${colors.blue700};
  }
`
const IncidentAnchor = styled.a`
  visibility: hidden;
`

const IncidentDate = styled.dt`
  ${incidentItem}
  color: #dde7f8;
  font-weight: 500;

  @media screen and (min-width: 768px) {
    padding-right: 40px;
  }

  :nth-child(1) {
    border-top: 0;
  }
`

const IncidentDetails = styled.dd`
  ${incidentItem}
  margin: 0 0 1.5em;
  color: #8192b2;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }

  :nth-child(2) {
    border-top: 0;
  }
`

const Incident = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

const IncidentHeader = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.blue500};
  margin-bottom: 2px;
`

const IncidentTime = styled.time`
  margin-right: 0.25em;
`

const IncidentContent = styled.div`
  color: ${colors.blue200};

  a {
    ${anchorStyle}
  }

  img {
    max-width: 100%;
  }
`

const ViewMore = styled.a`
  font-weight: 500;
  color: ${colors.blue400};
`

const Error = styled.div`
  color: ${colors.blue200};
  padding: 140px 0;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
`

function IncidentHistory({ incidentsByDay, error }) {
  const [periodInDays, setPeriodInDays] = useState(60)

  return (
    <StyledSection>
      <Heading2>Incident history</Heading2>
      {error && (
        <Error>
          <p>Failed to fetch history 😕</p>
          <p>{error.message}</p>
        </Error>
      )}
      <IncidentList>
        {incidentsByDay &&
          new Array(periodInDays).fill().map((_, daysAgo) => {
            const isToday = daysAgo === 0
            const date = startOfDay(subDays(new Date(), daysAgo))
            const incidents = incidentsByDay[date.toDateString()]
            const incident = incidents && incidents.length > 0 ? incidents[0] : {}
            if (!incident.datetime) {
              incident.datetime = date.toString()
            }
            if (!incident.text) {
              incident.text = isToday
                ? '✓ No incidents reported so far.'
                : '✓ 24h uptime, no incidents reported.'
            }

            let incidentHtml = incident.text.replace('\n', '<br /><br />')
            if (incident.regions && incident.regions.length > 0) {
              incidentHtml += `<br /><br />Affected regions/services: ${incident.regions.join(
                ', ',
              )}`
            }

            return (
              <Fragment key={date.toDateString()}>
                <IncidentDate>{format(date, 'MMM d, y')}</IncidentDate>
                <IncidentDetails>
                  <IncidentAnchor name={`incident-${format(date, 'y-MM-dd')}`} />
                  <Incident key={incident.id}>
                    <IncidentHeader>
                      <IncidentTime>{format(new Date(incident.datetime), 'p')}</IncidentTime>
                    </IncidentHeader>
                    <IncidentContent
                      dangerouslySetInnerHTML={{
                        __html: incidentHtml,
                      }}
                    />
                  </Incident>
                </IncidentDetails>
              </Fragment>
            )
          })}
      </IncidentList>

      {periodInDays === 7 && (
        <ViewMore
          href="#"
          onClick={(ev) => {
            ev.preventDefault()
            setPeriodInDays(14)
          }}
        >
          View more entries →
        </ViewMore>
      )}
    </StyledSection>
  )
}

export default IncidentHistory
