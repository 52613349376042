import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components/macro'
import { addDays, subDays, startOfDay, format, isAfter } from 'date-fns'
import ReactTooltip from 'react-tooltip'
import { transparentize } from 'polished'
import { Section } from './Html.js'
import colors from './colors.js'

const StyledSection = styled(Section)`
  margin-bottom: 40px;
  padding: 15px 15px 25px;
  background-color: ${colors.blue800};
  background: linear-gradient(
    180deg,
    ${colors.blue800} 0%,
    ${transparentize(0.2, colors.blue800)} 100%
  );
  box-shadow:
    0px 10px 40px rgba(0, 0, 0, 0.25),
    0px 3px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  @media screen and (min-width: 768px) {
    margin-bottom: 60px;
    padding: 20px 30px 25px;
    border-radius: 8px;
  }
`

const StatusItem = styled.div`
  color: ${colors.blue300};
  margin-bottom: 30px;
  font-size: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`

const StatusItemStatus = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-weight: 500;
  margin-bottom: 3px;

  @media screen and (min-width: 768px) {
    font-size: 11px;
  }

  color: ${(props) => (props.ok ? colors.green : colors.orange)};
`

const StatusItemHeader = styled.div`
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
`

const StatusItemTitle = styled.h2`
  margin: 0 0 0.2em;
  font-size: 16px;
  color: ${colors.blue100};

  @media screen and (min-width: 768px) {
    margin: 0;
    font-size: 18px;
  }
`

const StatusItemChart = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }
`

const StatusItemBar = styled.div`
  width: 9px;
  height: 34px;
  border-radius: 4px;
  border: 2px solid transparent;
  -webkit-background-clip: content-box;
  background-clip: content-box;

  // hide first 30 items on small phone screens
  @media screen and (max-width: 459px) {
    &:nth-child(-n + 30) {
      display: none;
    }
  }

  // hide first 15 items on narrow screens
  @media screen and (max-width: 599px) {
    &:nth-child(-n + 15) {
      display: none;
    }
  }

  @media screen and (min-width: 400px) {
    width: 10px;
    height: 39px;
    border-radius: 5px;
  }

  @media screen and (min-width: 880px) {
    width: 11px;
    height: 44px;
    border-radius: 6px;
  }

  @media screen and (min-width: 1024px) {
    width: 12px;
  }

  background-color: ${(props) => (props.ok ? colors.green : colors.orangeAlt)};
  transition: 0.05s background-color ease-in;

  &:hover {
    background-color: ${(props) => (props.ok ? colors.greenLight : colors.orangeLight)};
  }
`

const StatusItemLegend = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  opacity: 0.7;
`

const DaysAgo = styled.div`
  display: inline;
  @media screen and (max-width: 459px) {
    ::before {
      content: '30 ';
    }
  }
  @media screen and (min-width: 460px) {
    ::before {
      content: '45 ';
    }
  }
  @media screen and (min-width: 600px) {
    ::before {
      content: '60 ';
    }
  }
`

const Error = styled.div`
  color: ${colors.blue200};
  padding: 140px 0;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
`

class ServiceHealthDay {
  constructor({ date, healthy, incidents }) {
    this.date = date
    this.healthy = healthy
    this.incidents = incidents
  }
}

function Graphs({ services, incidentsByDay, error }) {
  const servicesHealth = useMemo(() => {
    if (!services || !incidentsByDay) return
    const servicesHealth = []
    for (const service of services) {
      servicesHealth[service.label] = []
    }

    for (let daysAgo = 0; daysAgo < 61; daysAgo++) {
      const date = startOfDay(subDays(new Date(), daysAgo))
      const incidents = incidentsByDay[date.toDateString()]
      if (!incidents) {
        for (const service of services) {
          servicesHealth[service.label].push(
            new ServiceHealthDay({
              date,
              healthy: true,
              incidents: [],
            }),
          )
        }
      } else {
        let foundServiceInIncident = false
        for (const service of services) {
          const serviceIsMentioned = service.keywords.find(
            (keyword) =>
              incidents.filter((incident) => incident.regions.includes(keyword)).length > 0,
          )
          if (serviceIsMentioned) {
            foundServiceInIncident = true
          }
          servicesHealth[service.label].push(
            new ServiceHealthDay({
              date,
              healthy: !serviceIsMentioned,
              incidents,
            }),
          )
        }
        if (!foundServiceInIncident) {
          for (const service of services) {
            if (service.downtimeIfNoServiceMentionedInIncident) {
              const idx = servicesHealth[service.label].length - 1
              servicesHealth[service.label][idx].healthy = false
            }
          }
        }
      }
    }

    return servicesHealth
  }, [services, incidentsByDay])

  useEffect(() => {
    if (servicesHealth) {
      ReactTooltip.rebuild()
    }
  }, [servicesHealth])

  return (
    <StyledSection>
      {error && (
        <Error>
          <p>Failed to fetch services 😕</p>
          <p>{error.message}</p>
        </Error>
      )}
      {servicesHealth &&
        services.map(({ label, online }) => {
          const serviceHealth = servicesHealth[label]
          return (
            <StatusItem key={label}>
              <StatusItemStatus ok={online}>
                {online ? '✓ Operational' : '× Non-operational'}
              </StatusItemStatus>
              <StatusItemHeader>
                <StatusItemTitle>{label}</StatusItemTitle>
                {/* <div>
                <strong>
                  {Math.round(serviceHealth.filter(Boolean).length / serviceHealth.length * 100)}%
                </strong>
                {' '}
                uptime in the last 30 days
              </div> */}
              </StatusItemHeader>
              <StatusItemChart>
                {[...serviceHealth].reverse().map(({ date, healthy, incidents }) => (
                  <StatusItemBar
                    ok={healthy}
                    key={`${label}${date.toString()}`}
                    data-tip={`
                    <div class="date">
                      ${format(date, 'MMMM d, y')}
                    </div>
                    ${
                      healthy
                        ? '✓ No incidents reported'
                        : isAfter(date, new Date('March 10, 2021'))
                          ? // We started using twitter threads on @TLStatus
                            `<ol>
                        ${incidents
                          .filter((Incident) => !Incident.in_reply_to_status_id_str)
                          .map(
                            (Incident) =>
                              `<li>
                              ${format(new Date(Incident.datetime), 'p')} ·
                              <a href="#incident-${format(date, 'y-MM-dd')}">
                                Incident
                              </a>
                            </li>`,
                          )
                          .join('')}
                      </ol>`
                          : `⚠️ <a
                        href="https://twitter.com/search?q=(from:TLStatus) since:${format(
                          date,
                          'y-MM-dd',
                        )} until:${format(addDays(date, 1), 'y-MM-dd')}"
                        target="_blank" rel="noreferrer noopener"
                      >
                        Incident(s) reported
                      </a>`
                    }
                  `}
                  />
                ))}
              </StatusItemChart>
              <StatusItemLegend>
                <DaysAgo>days ago</DaysAgo>
                <div>Today</div>
              </StatusItemLegend>
            </StatusItem>
          )
        })}
    </StyledSection>
  )
}

export default Graphs
