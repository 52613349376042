import styled, { css } from 'styled-components/macro'
import { transparentize, lighten } from 'polished'
import colors from './colors.js'

// For tweet rendering in IncidentHistory
export const anchorStyle = css`
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const Anchor = styled.a`
  ${anchorStyle}
`

export const Code = styled.code`
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  word-wrap: break-word;
  color: ${colors.turquoise};
  font-size: 0.9em;

  a {
    color: inherit;
    text-decoration: none !important;
    border-bottom: 1px solid ${transparentize(0.8, colors.turquoise)};

    &:hover {
      color: ${lighten(0.2, colors.turquoise)};
      border-bottom-color: ${lighten(0.2, transparentize(0.8, colors.turquoise))};
    }
  }
`

const heading = css`
  line-height: 1.2;
  color: #fff;
  margin: 0 0 0.67rem;
`

export const Heading2 = styled.h2`
  ${heading}
  font-size: 24px;

  @media screen and (min-width: 768px) {
    font-size: 36px;
  }
`

export const Heading3 = styled.h3`
  ${heading}
  font-size: 16px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
`

export const Paragraph = styled.p`
  margin: 0 0 1em;
`

export const Section = styled.section`
  margin-bottom: 60px;

  @media screen and (min-width: 768px) {
    margin-bottom: 120px;
  }
`
