import styled from 'styled-components/macro'
import colors from './colors.js'
import { Anchor } from './Html.js'
import Container from './Container.js'

const StyledFooter = styled.footer`
  margin-top: 80px;
  font-weight: 500;
  padding-bottom: 40px;

  @media screen and (min-width: 768px) {
    padding-bottom: 100px;
    margin-top: 140px;
  }
`

const Copyright = styled.div`
  ::before {
    content: '';
    display: block;
    width: 180px;
    height: 2px;
    background-color: ${colors.blue700};
    margin-bottom: 1em;
  }
`

function Footer() {
  return (
    <StyledFooter>
      <Container>
        <Copyright>
          © 2009-{new Date().getFullYear()} Transloadit
          <br />
          <Anchor href="https://transloadit.com/support/">Contact support</Anchor>
        </Copyright>
      </Container>
    </StyledFooter>
  )
}

export default Footer
