import * as Sentry from '@sentry/react'
import React from 'react'
import { Route as BaseRoute, Router, Switch } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { baseUrl } from './config.js'
import { history } from './history.js'
import MachIndex from './MachIndex.js'
import IncidentManagementPage from './Incidents.js'
import Statuspage from './Statuspage.js'

// Create Custom Sentry Route component
const Route = Sentry.withSentryRouting(BaseRoute)

const fetcher = (path) =>
  fetch(new URL(path, baseUrl)).then((res) =>
    res.ok
      ? res.json()
      : Promise.reject(new Error(`Failed to load with status ${res.status} ${res.statusText}`)),
  )

function TransloaditStatusPageApp() {
  return (
    <Sentry.ErrorBoundary>
      <SWRConfig value={{ fetcher }}>
        <Router history={history}>
          <Switch>
            <Route path="/machindex/:query?">
              <MachIndex />
            </Route>
            <Route path="/manage-incidents/">
              <IncidentManagementPage />
            </Route>
            <Route path="/">
              <Statuspage />
            </Route>
          </Switch>
        </Router>
      </SWRConfig>
    </Sentry.ErrorBoundary>
  )
}

export default Sentry.withProfiler(TransloaditStatusPageApp)
