// eslint-disable-next-line no-undef
export const baseUrl = process.env.REACT_APP_API_HOST ?? window.location.origin

// Keep in sync with server/config.js

export const regions = {
  'us-east-1': {
    label: 'US East',
    keywords: ['us-east-1', 'US', 'United States'],
  },
  // 'us-west-2': {
  //   label   : 'US West',
  //   keywords: [
  //     'us-west-2',
  //     'US',
  //     'United States',
  //   ],
  // },
  'eu-west-1': {
    label: 'Europe West',
    keywords: ['eu-west-1', 'Europe', 'EU'],
  },
  // 'eu-south-1': {
  //   label   : 'Europe South',
  //   keywords: [
  //     'eu-south-1',
  //     'Europe',
  //     'EU',
  //   ],
  // },
  'ap-southeast-1': {
    label: 'Asia Pacific',
    keywords: ['ap-southeast-1', 'Asia'],
  },
}
