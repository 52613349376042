import 'normalize.css'
import styled, { createGlobalStyle } from 'styled-components/macro'
import ReactTooltip from 'react-tooltip'
import { transparentize } from 'polished'
import colors from './colors.js'
import { anchorStyle } from './Html.js'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    background-color: ${colors.blue900};
    color: ${colors.blue200};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (min-width: 768px) {
      font-size: 18px;
    }
  }

  .react-tooltip {
    .date {
      color: white;
    }
    color: ${colors.blue300} !important;
  }
`

const StyledReactTooltip = styled(ReactTooltip)`
  color: ${colors.blue300} !important;
  padding: 9px 40px 9px 14px !important;
  max-width: 260px !important;
  border-radius: 8px !important;
  opacity: 1 !important;
  box-shadow: 0 0 0 1px ${transparentize(0.15, colors.blue700)} !important;
  background-color: ${transparentize(0.05, colors.blue950)} !important;

  &::after {
    border-top-color: ${transparentize(0.05, colors.blue950)} !important;
    filter: drop-shadow(0px 1px 0 ${transparentize(0.15, colors.blue700)});
  }

  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ol li {
    margin: 0 0 0.5em;
    padding: 0;
    font-variant-numeric: tabular-nums;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .date {
    color: white;
    font-weight: 500;
    margin-bottom: 0.33em;
  }

  a {
    ${anchorStyle}
    color: ${colors.blue200};
    text-decoration: underline;

    &:hover {
      color: ${colors.blue100};
    }
  }
`

function Layout({ children }) {
  return (
    <>
      <GlobalStyle />
      <StyledReactTooltip html clickable effect="solid" delayHide={500} />
      {children}
    </>
  )
}

export default Layout
