import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'
import Container from '../Container.js'
import colors from '../colors.js'
import { Anchor } from '../Html.js'
import LogoSVG from './logo.svg'

const StyledHeader = styled.header`
  margin: 20px 0;

  @media screen and (min-width: 768px) {
    margin: 25px 0 40px;
  }
`

const Inner = styled.div`
  @media screen and (min-width: 640px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
`

const LogoImage = styled.img`
  margin-right: 10px;
  width: 140px;

  @media screen and (min-width: 768px) {
    width: auto;
  }
`

const LogoExtra = styled.h1`
  display: flex;
  margin: 0;
  line-height: 1.4;
  align-items: center;
  text-transform: uppercase;
  color: ${colors.blue400};
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;

  @media screen and (min-width: 768px) {
    font-size: 14px;
  }

  &::before {
    content: '';
    display: block;
    margin-right: 10px;
    width: 1px;
    height: 30px;
    background-color: ${colors.blue400};
    opacity: 0.5;
  }
`

const GoBackHome = styled(Anchor)`
  display: none;
  color: ${colors.blue400};

  @media screen and (min-width: 640px) {
    display: inline;
  }
`

function Header({ children, className }) {
  return (
    <>
      <Helmet>
        <title>{children} | Transloadit</title>
      </Helmet>
      <StyledHeader className={className}>
        <Container>
          <Inner>
            <Logo>
              <Anchor href="https://transloadit.com">
                <LogoImage src={LogoSVG} alt="Transloadit" />
              </Anchor>
              <LogoExtra>{children}</LogoExtra>
            </Logo>

            <GoBackHome href="https://transloadit.com">Go to Transloadit.com →</GoBackHome>
          </Inner>
        </Container>
      </StyledHeader>
    </>
  )
}

export default Header
